import { Button, Image } from "react-bootstrap";

import leftArrow from "../../assets/images/arrow-left-icon.svg";
import { useNavigate } from "react-router-dom";
import { TERMS } from "../../AppConstants/AppConstants";

export const OfficialRulesComponent = () => {
  const navigate = useNavigate();

  //handle to go to previous page
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleTermsPage = () => {
    console.log('term')
    navigate(TERMS);
  }

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
      <div className="sg-content-section d-flex flex-column flex-1 position-relative scroll-y py-5">
        {/* <HeaderComponent /> */}
        <div className="terms-content-wrap d-flex flex-column flex-1 scroll-y px-3 pb-5">
          {/* <p><strong>Terms and Conditions</strong></p> */}
          <h2 className=" fw-bold mb-3">
          Terms and Conditions
          </h2>
          <p>By entering your email to receive your personalized Playbill Magazine Cover, you consent to The Prudential Insurance Company of America ("Prudential") contacting you for marketing purposes.</p>
          <p>You may opt out at any time by clicking 'unsubscribe' within future emails. See Prudential's Privacy Policy at <a href='https://www.prudential.com/links/privacy-policy' target="_blank" className="text-primary" >https://www.prudential.com/links/privacy-policy</a></p>
          <p>By using the Prudential Selfie Generator, you consent to use of your image, name, voice, likeness, actions, and/or statements in any live display or other recording taken at or in connection with Curtain Up Broadway Festival Presented by Prudential during or following such event by Playbill Inc, The Prudential Insurance Company of America and/or any of their affiliates, licensees, sponsors, other designees for any purpose (including commercial, advertising or promotional purposes) and in any media known or subsequently developed, without additional compensation, authorization or notice. © 2023 Prudential Financial, Inc. and its related entities. Prudential, the Prudential logo and the Rock symbol are service marks of Prudential Financial, Inc. and its related entities, registered in many jurisdictions worldwide. Investment Products: Not FDIC-insured * No Bank Guarantee * May Lose Value</p>
          
        </div>
        <div className="back-fix-btn w-100 p-3 d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            className="fs-md text-uppercase fw-bold rounded-0 py-1 px-3 text-dark text-decoration-none"
            onClick={handleGoBack}
          >
            <Image src={leftArrow} alt="right arrow" color="#000000" className="me-2 text-black" />
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

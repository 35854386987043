import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { APP_BASE_ROUTE, BILLBOARDTYPE, ROUTES } from "./AppConstants/AppConstants";
import { useEffect, useState } from "react";
import axios from "axios";
import AppContext from "./AppContext";

export const RoutesConfig = () => {

  const [billboardType, setBillboardType] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [prudentialMarketingAccepted, setPrudentialMarketingAccepted] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneMarketingAccepted, setPhoneMarketingAccepted] = useState(false);

  return (
    <AppContext.Provider value={{ billboardType, setBillboardType, firstName, setFirstName, lastName, setLastName, email, setEmail, zipCode, setZipCode, prudentialMarketingAccepted, setPrudentialMarketingAccepted, phoneNumber, setPhoneNumber,phoneMarketingAccepted, setPhoneMarketingAccepted }} >
      <BrowserRouter basename={APP_BASE_ROUTE}>
        <Toaster toastOptions={{ className: "cu-toaster border border-secondary" }} containerStyle={{
          top: "50%",
        }} />
        <Routes>
          {ROUTES.map((route, i) => (
            <Route key={i} exact path={route.path} element={route.element}></Route>
          ))}
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

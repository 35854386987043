import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { CapturePhotoComponent } from "./capturePhotoComponent";

import {
  NONE,
  INSTRUCTION,
  BACKGROUND,
  REMOVEBG_API_KEY,
  REMOVEBG_API_PATH,
  UNKNOWN_FOREGROUND,
  CAMERA,
  BLACKANDWHITE,
  VIBRANT,
} from "../../AppConstants/AppConstants";

import { base64ToBlob } from "../../helpers";

export const CapturePhotoContainer = () => {
  //Used to get the image data passed from useNavigate()
  const { state } = useLocation();
  const navigate = useNavigate();
  const totalImagesToBeLoaded = 4;
  const imageLoadCount = useRef(0);

  const [croppedImage, setCroopedImage] = useState(
    localStorage.getItem("bgRemovedImage") || null
  );
  const [originalImage, setOriginalImage] = useState(
    localStorage.getItem("bgRemovedImage") || null
  );
  const [activeFilter, setActiveFilter] = useState(NONE);

  const [showActiveBtn, setActiveBtnVisibility] = useState(BACKGROUND);
  const [activeButton, setActiveButton] = useState(BACKGROUND);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [loaderText, setloaderText] = useState("Processing Image...");

  //handle to show active button
  const handleBtnChange = (item) => {
    if (typeof window !== "undefined") {
      window.gtag("event", "edit_photo");
    }
    setActiveBtnVisibility(item);
    setActiveButton(item);
  };

  //Handle to go previous page
  const handleGoPrevious = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "back_instruction");
    }
    navigate(INSTRUCTION);
  }, [navigate]);

  // handle on foreground image load
  const onImageLoad = (e) => {
    imageLoadCount.current += 1;
    setIsImageLoading(imageLoadCount.current < totalImagesToBeLoaded);
  };

  // on background remove failed
  const onBackgroundRemovalFailed = useCallback(() => {
    toast.error(
      "Sorry!, We are not able to process this request. Please try again!"
    );
    navigate(CAMERA);
  }, [navigate]);

  // function to remove bg from an image
  const removeBackground = useCallback(async () => {
    try {
      setloaderText("Removing Background...");

      const convertedImage = await resizeImageBase64(
        state?.imageSrc,
        1024,
        1024
      );

      const blob = await base64ToBlob(convertedImage);
      const formData = new FormData();

      formData.append("image_file", blob);
      formData.append("size", "full");

      // remove background api
      const response = await fetch(REMOVEBG_API_PATH, {
        method: "POST",
        headers: {
          "X-Api-Key": REMOVEBG_API_KEY,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.blob();
        let urlCreator = window.URL || window.webkitURL;

        let imageSrc1 = urlCreator.createObjectURL(result);
        // save image in localstorage as base64 URL
        localStorage.setItem("bgRemovedImage", imageSrc1);
        setCroopedImage(imageSrc1);
        setOriginalImage(imageSrc1);
      } else {
        const { errors } = (await response.json()) || {};
        if (errors[0]?.code === UNKNOWN_FOREGROUND) {
          toast.error(
            "Sorry, We could not detect any human face! Please try again!"
          );

          return navigate(CAMERA);
        }
        onBackgroundRemovalFailed();
        console.error("Background removal failed:", response.statusText);
        setCroopedImage(null);
      }
    } catch (error) {
      console.error("Background removal failed:", error);
      onBackgroundRemovalFailed();

      setCroopedImage(null);
    } finally {
      setIsLoading(false);
    }
  }, [navigate, onBackgroundRemovalFailed, state?.imageSrc]);

  useEffect(() => {
    async function applyFilter() {
      if (activeFilter && originalImage) {
        setCroopedImage(await applyImageEffects(originalImage, activeFilter));
      }
    }
    applyFilter();
  }, [activeFilter]);

  useEffect(() => {
    setIsImageLoading(false);
    setIsLoading(false);
    if (imageLoadCount) {
      imageLoadCount.current = 0;
    }
  }, []);

  // life cycle efffects
  useEffect(() => {
    if (!isLoading) {
      setIsImageLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (croppedImage) {
      return setIsLoading(false);
    }
    window.history.pushState(null, null, "/staronplaybill");
    removeBackground();
  }, [croppedImage, removeBackground]);

  function resizeImageBase64(base64Image, maxWidth, maxHeight) {
    return new Promise(function (resolve, reject) {
      const img = new Image();

      img.onload = function () {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;

          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }

          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        const resizedBase64 = canvas.toDataURL("image/jpeg"); // You can change the format if needed

        resolve(resizedBase64);
      };

      img.onerror = function (error) {
        reject(error);
      };

      img.src = base64Image;
    });
  }
  function applyImageEffects(src, effect) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous"; // Set crossOrigin to handle CORS issues if applicable
      img.src = src;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        switch (effect) {
          case NONE:
            // No effect, keep the image as is
            break;
          case BLACKANDWHITE:
            // Convert the image to black and white
            for (let i = 0; i < data.length; i += 4) {
              const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
              data[i] = avg;
              data[i + 1] = avg;
              data[i + 2] = avg;
            }
            break;
          case VIBRANT:
            // Adjust brightness and contrast
            const brightnessFactor = 1.2; // Increase brightness by 20%
            const contrastFactor = 1.3; // Increase contrast by 30%
            for (let i = 0; i < data.length; i += 4) {
              // Adjust brightness
              data[i] *= brightnessFactor;
              data[i + 1] *= brightnessFactor;
              data[i + 2] *= brightnessFactor;

              // Adjust contrast
              data[i] = (data[i] - 128) * contrastFactor + 128;
              data[i + 1] = (data[i + 1] - 128) * contrastFactor + 128;
              data[i + 2] = (data[i + 2] - 128) * contrastFactor + 128;
            }
            break;
          default:
            reject(new Error("Invalid effect specified"));
            return;
        }

        ctx.putImageData(imageData, 0, 0);

        // Get the base64 representation of the adjusted image
        const adjustedImageSrc = canvas.toDataURL("image/png");
        resolve(adjustedImageSrc);
      };

      img.onerror = function () {
        reject(new Error("Image loading failed"));
      };
    });
  }

  return (
    <CapturePhotoComponent
      croppedImage={croppedImage}
      setCroopedImage={setCroopedImage}
      handleGoPrevious={handleGoPrevious}
      setActiveFilter={setActiveFilter}
      activeFilter={activeFilter}
      showActiveBtn={showActiveBtn}
      activeButton={activeButton}
      handleBtnChange={handleBtnChange}
      setActiveBtnVisibility={setActiveBtnVisibility}
      setActiveButton={setActiveButton}
      isLoading={isLoading}
      onImageLoad={onImageLoad}
      loaderText={loaderText}
      isImageLoading={isImageLoading}
      setIsImageLoading={setIsImageLoading}
    />
  );
};

import React, { useCallback } from "react";
import { useNavigate } from "react-router";

import InstructionScreenComponent from "./InstructionScreenComponent";

import { CAMERA } from "../../AppConstants/AppConstants";

import prudentialLogo from "../../assets/images/prudential-curtainUp-logo.png";

export const InstructionScreenContainer = () => {
  const navigate = useNavigate();

  //Handle to open camera on get started button on instruction page
  const handleOnClick = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "take_selfie");
    }
    navigate(CAMERA);
  }, [navigate]);

  return (
    <InstructionScreenComponent
      prudentialLogo={prudentialLogo}
      handleOnClick={handleOnClick}
    />
  );
};

import React, { useContext, useEffect, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import { Button, Form, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as htmlToImage from "html-to-image";

import {
  BACKGROUND,
  PHOTO,
  TEXT,
  BG1,
  IMG1,
  BUTTONLIST,
  THEME1,
  USERINFO,
  GRID_SIZE,
  CURTAIN_UP_1,
} from "../../AppConstants/AppConstants";

import { BackgroundPhotoContainer } from "../backgroundPhoto/backgroundPhotoContainer";
import { TextEditorComponent } from "../textEditor/textEditorComponent";
import { PhotoFilterComponent } from "../photoFilter/photoFilterComponent";
import Loader from "../../components/custom/Loader";

import leftArrow from "../../assets/images/left-arrow-icon.svg";
import rightArrow from "../../assets/images/right-arrow-icon.svg";
import playBill from "../../assets/images/bg-playbill.png";

import "./capturePhoto.scss";
import AppContext from "../../AppContext";
import { UPLOAD_FILE_API_PATH } from "../../AppConstants/AppConstants";
import { toast } from "react-hot-toast";
import client from "../../services/axios";

export const CapturePhotoComponent = ({
  croppedImage,
  setCroopedImage,
  handleGoPrevious,
  setActiveFilter,
  activeFilter,
  handleBtnChange,
  activeButton,
  showActiveBtn,
  isLoading,
  onImageLoad,
  loaderText,
  isImageLoading,
  setIsImageLoading,
}) => {
  const minZoom = 0.4;
  const imgRef = useRef(null);
  const navigate = useNavigate();

  const [activeImg, setActiveImg] = useState(BG1);
  const [selectedImg, setSelectedImg] = useState({
    background: IMG1,
    title: CURTAIN_UP_1,
  });
  const { firstName, setFirstName } = useContext(AppContext);
  const { lastName, setLastName } = useContext(AppContext);
  const [rotation, setRotation] = useState(0);
  const [isCustomText, setIsCustom] = useState(false);
  const [themeClass, setThemeClass] = useState(THEME1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [showGrid, setShowGrid] = useState(true);
  const [imageLoaderText, setImageLoaderText] = useState("Loading images...");
  // Rotate the image by 90 degrees

  const handleRotate = (evt) => {
    if (typeof window !== "undefined") {
      window.gtag("event", "photo_rotate");
    }
    setRotation(evt.target.value);
  };

  const handleFirstNameChange = (event) => {
    if (typeof window !== "undefined") {
      window.gtag("event", "change_fname");
    }
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    if (typeof window !== "undefined") {
      window.gtag("event", "change_lname");
    }
    setLastName(event.target.value);
  };

  // Increase this value to increase the grid size
  const cropSize = { width: GRID_SIZE, height: GRID_SIZE };

  // Increase this value to allow more zoom
  const maxZoom = 3;

  //handle to apply filter
  const handleFiltreApplication = (filterName, ga_title) => {
    if (typeof window !== "undefined") {
      window.gtag("event", ga_title);
    }
    setActiveFilter(filterName);
  };

  function downloadSVGAsText() {
    const svg = document.querySelector("svg");
    const base64doc = btoa(unescape(encodeURIComponent(svg.outerHTML)));
    const a = document.createElement("a");
    const e = new MouseEvent("click");
    a.download = "download.svg";
    a.href = "data:image/svg+xml;base64," + base64doc;
    a.dispatchEvent(e);
  }

  function downloadSVGAsPNG(base64doc) {
    console.log("svgHtml", base64doc);
    const canvas = document.createElement("canvas");
    const w = 810;
    const h = 1050;
    const img_to_download = document.createElement("img");
    img_to_download.src = "data:image/png;base64," + base64doc;
    img_to_download.onload = async function () {
      canvas.setAttribute("width", w);
      canvas.setAttribute("height", h);
      const context = canvas.getContext("2d");
      context.drawImage(img_to_download, 0, 0, w, h);
      canvas.toDataURL("image/png");
      canvas.toBlob(async (blob) => {
        const formData = new FormData();
        formData.append(
          "file",
          blob,
          Math.floor(Math.random() * 10 + 1) + "preview.png"
        );
        const response = await client.upload(
          "token",
          UPLOAD_FILE_API_PATH,
          formData
        );
        if (!response?.success) {
          toast.error(response?.message);
        } else {
          if (response?.data?.key);
          setIsImageLoading(false);
          setImageLoaderText("");
          toast.success(response.message);
          const imageDataURL = response.data.key;
          navigate(USERINFO, {
            state: {
              imageDataURL,
              firstName,
              lastName,
            },
          });
        }
      });
    };
  }

  //Handle to go next page with created image usign DOM element
  const handleGoNext = async () => {
    if (typeof window !== "undefined") {
      window.gtag("event", "go_userinfo");
    }

    setShowGrid(false);
    setIsImageLoading(true);
    setImageLoaderText("Loading...");

    const imageElement = document.getElementsByClassName(
      "reactEasyCrop_CropArea"
    )[0];
    if (imageElement) imageElement.style.border = "none";

    const node = document.getElementById("previewDiv");

    setTimeout(async () => {
      let imageDataURL;
      for (var i = 0; i < 10; i++) {
        imageDataURL = await htmlToImage.toPng(node, { cacheBust: false });
      }
      const svgContentWithoutPrefix = imageDataURL.replace(
        /^data:image\/png;base64,/,
        ""
      );
      downloadSVGAsPNG(decodeURIComponent(svgContentWithoutPrefix));
    }, 500);
  };

  // life cycle effects
  useEffect(() => {
    const handleGridVisibility = (event) => {
      if (imgRef.current && !imgRef.current.contains(event.target)) {
        setShowGrid(false);
      } else {
        setShowGrid(true);
      }
    };

    window.addEventListener("wheel", handleGridVisibility);
    window.addEventListener("click", handleGridVisibility);

    return () => {
      window.removeEventListener("click", handleGridVisibility);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("beforeunload", function (evt) {
      const confirmationMessage =
        "It looks like you have been editing something, Leaving site may lose all changes! ";

      evt.returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    });
  }, []);

  const isMobileDevice = window.innerWidth <= 768; // Set your breakpoint as needed

  // Calculate the width for the image box
  const viewportWidth = isMobileDevice ? Math.min(window.innerWidth) : 420; // Adjust the desktop width as needed
  const imageBoxStyle = {
    width: `${viewportWidth}px`,
    height: `${viewportWidth}px`,
  };

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section bg-secondary d-flex flex-column flex-1">
        {isImageLoading && !isLoading && (
          <Loader
            zIndex={9999}
            loaderText={imageLoaderText}
            customClasses="text-lg"
          />
        )}
        {isLoading ? (
          <Loader loaderText={loaderText} />
        ) : (
          <div
            style={{ ...(isImageLoading && { opacity: "0.7" }) }}
            className="capture-edit-wrapper d-flex flex-column align-items-center justify-content-center flex-1"
          >
            <div className="px-5" style={imageBoxStyle}>
              <div id="previewDiv" className="edit-outline-box">
                <div className="preview-box d-flex flex-column w-100 h-100">
                  <div className="sg-image-container d-flex flex-column align-items-center justify-content-center position-relative overflow-hidden mx-auto">
                    <Image
                      src={playBill}
                      alt="Play Bill"
                      className="sg-playbill-img w-100"
                      onLoad={onImageLoad}
                    />
                    <div className="preview-wrap d-flex w-100 position-relative">
                      <Image
                        onLoad={onImageLoad}
                        className="sg-user-bg w-100"
                        id="back-img"
                        src={selectedImg?.background}
                        alt="background-img"
                      />
                      <div className="sg-react-cropper" ref={imgRef}>
                        <Cropper
                          minZoom={minZoom}
                          image={croppedImage}
                          crop={crop}
                          zoom={zoom}
                          onMediaLoaded={onImageLoad}
                          aspect={3 / 3}
                          onCropChange={(newCrop) => {
                            if (!showGrid) setShowGrid(false);
                            setCrop(newCrop);
                          }}
                          onZoomChange={setZoom}
                          id="front-img"
                          cropSize={cropSize}
                          maxZoom={maxZoom}
                          rotation={rotation}
                          showGrid={showGrid}
                          restrictPosition={false}
                          transform={`translateX(${crop.x}px) translateY(${crop.y}px) rotate(${rotation}deg) scale(${zoom})`}
                        />
                      </div>
                      <div
                        className={`sg-text-variation-wrapper position-absolute w-100 ${themeClass} overflow-hidden`}
                      >
                        <Image
                          src={selectedImg?.title}
                          alt="image"
                          onLoad={onImageLoad}
                          className="shuffle-img position-absolute"
                        />

                        <div className="user-info position-absolute">
                          <div className="name-label-wrap">
                            <span className="text-uppercase">Starring</span>
                          </div>
                          <div className="user-name-wrap">
                            <Form.Group controlId="formFirstName">
                              <Form.Control
                                type="name"
                                name="first_name"
                                className="form-control user-name text-uppercase text-white mb-0 border-0 bg-transparent p-0"
                                placeholder="First name"
                                defaultValue={firstName}
                              />
                            </Form.Group>
                            <Form.Group controlId="lastName">
                              <Form.Control
                                type="name"
                                name="last_name"
                                className="form-control user-name user-last-name text-uppercase text-white mb-0 border-0 bg-transparent p-0"
                                placeholder="Last name"
                                defaultValue={lastName}
                              />
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sg-edit-action-section d-flex flex-column gap-4 w-100 p-4">
              <div className="sg-action-btns d-flex align-items-center justify-content-center gap-3">
                <Button
                  variant="link"
                  className="border-0 p-1"
                  onClick={handleGoPrevious}
                >
                  <Image src={leftArrow} alt="left arrow" />
                </Button>
                {BUTTONLIST.map((item, i) => (
                  <Button
                    key={i}
                    variant="info"
                    className={`fs-xs fw-semibold rounded-pill border-0 px-3 py-2 ${
                      activeButton === item ? "active" : ""
                    }`}
                    onClick={() => handleBtnChange(item)}
                  >
                    {item}
                  </Button>
                ))}
                <Button
                  variant="link"
                  className="border-0 p-1"
                  onClick={handleGoNext}
                >
                  <Image src={rightArrow} alt="right arrow" />
                </Button>
              </div>
              <div>
                {showActiveBtn === BACKGROUND && (
                  <BackgroundPhotoContainer
                    setSelectedImg={setSelectedImg}
                    setActiveImg={setActiveImg}
                    activeImg={activeImg}
                    setThemeClass={setThemeClass}
                    setIsCustom={setIsCustom}
                  />
                )}
                {showActiveBtn === PHOTO && (
                  <PhotoFilterComponent
                    setActiveFilter={setActiveFilter}
                    handleRotate={handleRotate}
                    activeFilter={activeFilter}
                    handleFiltreApplication={handleFiltreApplication}
                  />
                )}
                {showActiveBtn === TEXT && (
                  <div>
                    <TextEditorComponent
                      setFirstName={setFirstName}
                      setLastName={setLastName}
                      handleFirstNameChange={handleFirstNameChange}
                      handleLastNameChange={handleLastNameChange}
                      isCustomText={isCustomText}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

import React from "react";
import { Form } from "react-bootstrap";

import "./textEditor.scss";
import { MAX_CHAR_LENGTH } from "../../AppConstants/AppConstants";

export const TextEditorComponent = ({
  handleFirstNameChange,
  handleLastNameChange,
}) => {
  return (
    <div className="sg-text-editor d-flex flex-column gap-3">
      <Form.Group controlId="formBasicName">
        <Form.Control
          type="name"
          name="first_name"
          className="form-control rounded-pill fw-semibold text-center border-0 px-3 py-2"
          placeholder="First name"
          onChange={handleFirstNameChange}
          maxLength={MAX_CHAR_LENGTH}
        />
      </Form.Group>
      <Form.Group controlId="formBasicLastName">
        <Form.Control
          type="name"
          name="last_name"
          className="form-control rounded-pill fw-semibold text-center border-0 px-3 py-2"
          placeholder="Last name"
          onChange={handleLastNameChange}
          maxLength={MAX_CHAR_LENGTH}
        />
      </Form.Group>
    </div>
  );
};

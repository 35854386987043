import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ConfirmationScreenComponent from "./confirmationScreenComponent";

import { CAMERA, CAPTUREDPHOTO } from "../../AppConstants/AppConstants";

export const ConfirmationScreenContainer = () => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const [uploadPic, setUploadPic] = useState({ imageSrc: state?.imageSrc });

  //handle to go photo filtration page
  const handleNextClick = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "confirm_photo");
    }
    navigate(CAPTUREDPHOTO, { state: state });
  }, [navigate, state]);

  //handle to go back to retake selfie
  const handleRecaptureClick = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "retake_photo");
    }
    navigate(CAMERA);
  }, [navigate]);

  useEffect(() => {
    const storedCroppedImageURL = localStorage.getItem("croppedImageURL");
    if (storedCroppedImageURL) {
      navigate(CAMERA);
      localStorage.removeItem("croppedImageURL");
    }
  }, [navigate]);

  useEffect(() => {
    localStorage.removeItem("bgRemovedImage");
  }, []);

  return (
    <ConfirmationScreenComponent
      handleRecaptureClick={handleRecaptureClick}
      handleNextClick={handleNextClick}
      croppedImg={uploadPic?.imageSrc}
      state={state}
    />
  );
};

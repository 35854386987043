import React from "react";
import { Spinner } from "react-bootstrap";

export default function Loader({
  showLoaderText = true,
  loaderText = "Loading",
  zIndex = 1,
  customClasses
}) {
  return (
    <div className={`ele-center ${customClasses}`} style={{ zIndex }}>
      <div className="d-flex flex-column align-items-center gap-2">
        <Spinner animation="border" role="status" />
        {showLoaderText && <span>{loaderText}</span>}
      </div>
    </div>
  );
}

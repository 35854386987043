import { Button, Col, Row, Image, Form } from "react-bootstrap";

import CirularCheckbox from "../../components/custom/CirularCheckbox";

import "./userInfo.scss";
import { useContext, useState } from "react";
import AppContext from "../../AppContext";
import {
  MAX_CHAR_LENGTH,
  OFFICIAL_RULES,
  PRIVACY_POLICY,
  RELEASE,
  TERMS_PAGE,
  userInfo_billboard_content,
  userInfo_default_content,
} from "../../AppConstants/AppConstants";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/custom/Loader";

const UserInfoComponent = ({
  firstName,
  lastName,
  email,
  phoneNumber,
  phoneMarketingAccepted,
  zipCode,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setZipCode,
  handleSubmit,
  userInfoImage,
  prudentialMarketingAccepted,
  setPrudentialMarketingAccepted,
  isEventLive,
  disableAgreeBtn,
  setPhoneMarketingAccepted,
  showLoader,
}) => {
  const navigate = useNavigate();
  const billboardType = useContext(AppContext);
  //handle disable right click of browser
  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^[0-9+]*$/.test(value)) {
      setPhoneNumber(value);
    }
  }

  const handleZipCodeChange = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value)) {
      setZipCode(value);
    }
  }

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setIsValidEmail(emailPattern.test(inputEmail));
  };
  
  const handlePrivacyPolicy = () => {
    navigate(PRIVACY_POLICY)
  };
  
  const handleRelease = () => {
    navigate(RELEASE)
  };

  const handleTermsPage = () => {
    navigate(TERMS_PAGE)
  }

  const divStyle = {
    backgroundImage: `url(${userInfoImage})`,
    height: '200px',        
    width: '154.28px',      
    backgroundSize: 'cover',
    backgroundPosition: 'center',
};

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section bg-secondary d-flex flex-column flex-1 p-4">
        <Form
          onSubmit={handleSubmit}
          className="user-info-wrapper d-flex flex-column align-items-center justify-content-center flex-1"
        >
          {showLoader ? (
            <Loader
              zIndex={9999}
              loaderText="Your Playbill cover is processing!"
              customClasses="preview-loader text-lg text-primary"
            />
          ) : (
            
              <div id="image-example" style={divStyle} onContextMenu={handleContextMenu}></div>
          )}

          <p className="fs-md fw-semibold text-white text-center mb-3 padding">
            {isEventLive ? (
              <>
                {billboardType?.billboardType
                  ? userInfo_billboard_content 
                  : userInfo_default_content }
                {billboardType?.billboardType ? <span className="no-wrapper"> Time Square </span> : <></>}
              </>
            ) : (
              <>
                {" "}
                Complete to receive your Playbill front cover and share with
                friends.
              </>
            )}
          </p>
          <div className="d-flex flex-column gap-2 w-100 mb-4 px-4">
            <Row>
              <Col xs={6} className="p-1">
                <Form.Group>
                  <Form.Control
                    id="formBasicName"
                    type="text"
                    name="firstName"
                    maxLength={MAX_CHAR_LENGTH} 
                    className="form-control placeholder-white rounded-pill fw-semibold text-center px-3 py-2 text-white"
                    placeholder="First name"
                    value={firstName}
                    onChange={(evt) => setFirstName(evt.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="p-1">
                <Form.Group>
                  <Form.Control
                    id="lastName"
                    type="text"
                    name="lastName"
                    maxLength={MAX_CHAR_LENGTH} 
                    className="form-control placeholder-white rounded-pill fw-semibold text-center px-3 py-2 text-white"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(evt) => setLastName(evt.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="p-1">
                <Form.Group>
                  <Form.Control
                    id="phoneNumber"
                    type="tel"
                    name="phoneNumber"
                    className="form-control placeholder-white rounded-pill fw-semibold text-center px-3 py-2 text-white"
                    placeholder="Phone number"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} className="p-1">
                <Form.Group>
                  <Form.Control
                    id="zipCode"
                    type="tel"
                    name="zipCode"
                    className="form-control placeholder-white rounded-pill fw-semibold text-center px-3 py-2 text-white"
                    placeholder="Zip"
                    value={zipCode}
                    onChange={handleZipCodeChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="justify-content-center">
            <Col xs={12} className="p-1">
                <Form.Group>
                  <Form.Control
                    id="email"
                    type="email"
                    name="email"
                    className="form-control placeholder-white rounded-pill fw-semibold text-center px-3 py-2 text-white"
                    placeholder="Email"
                    value={email}
                    onBlur={handleEmailChange}
                    isInvalid={!isValidEmail}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a valid email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className="px-4">
          <p className="mb-0 fs-sm text-white mb-4 ">
             By using this website, you agree to Prudential's <a target="_blank" className="custom-link" onClick={handlePrivacyPolicy}>Privacy Policy </a> , <a target="_blank" className="custom-link" onClick={handleRelease}><span className="text-primary">Release </span></a>and <Link to={OFFICIAL_RULES} className="custom-link" >Terms and Conditions</Link>, confirm that you are at least 18 years old and agree that Prudential may use your image in advertising or otherwise.
          </p>
          {/* <p className="mb-0 fs-sm text-white mb-4 ">
             By using this website, you agree to Prudential's Privacy Policy, <span className="text-primary"> Release</span>, Terms and Conditions, confirm that you are at least 18 years old and agree that Prudential may use your image in advertising or otherwise.
          </p> */}
          
          <div className="d-flex justify-content-start gap-2 mb-2">
            <CirularCheckbox
              isChecked={prudentialMarketingAccepted}
              toggleIsChecked={() => {
                if (typeof window !== "undefined") {
                  window.gtag("event", "receive_info");
                }
                setPrudentialMarketingAccepted(!prudentialMarketingAccepted);
              }}
            />

            <p className="mb-0 fs-sm text-white">
            Yes, I want to subscribe to receive Prudential emails
            </p>
          </div>
          <div className="d-flex justify-content-start gap-2 mb-4">
            <CirularCheckbox
              isChecked={phoneMarketingAccepted}
              toggleIsChecked={() => {
                if (typeof window !== "undefined") {
                  window.gtag("event", "receive_info");
                }
                setPhoneMarketingAccepted(!phoneMarketingAccepted);
              }}
            />

            <p className="mb-0 fs-sm text-white">
            Yes, I'd like to be contacted by a Prudential financial advisor. *See <a target="_blank" className="custom-link" onClick={handleTermsPage}>Terms</a>.
            </p>
          </div>
          
          
          <p className="mb-0 fs-sm text-white mb-4 ">
          *Standard Carrier rates for calls, text, SMS, and/or MMS may apply. Please check with your carrier about your rate plan for questions regarding your cellular plan charges.
          </p>
          </div>
          
          <Button
            type="submit"
            variant="primary"
            className="fs-md fw-semibold rounded-pill px-5"
            onClick={handleSubmit}
            disabled={disableAgreeBtn}
          >
            Agree & Finish
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default UserInfoComponent;

import React from "react";

import {
  ACTIVE,
  BG1,
  BG2,
  BG3,
  bg_ga_title1,
  bg_ga_title2,
  bg_ga_title3,
  CURTAIN_UP_1,
  CURTAIN_UP_2,
  CURTAIN_UP_3,
  IMG1,
  IMG2,
  IMG3,
  THEME1,
  THEME2,
  THEME3,
  THUMB_BG1,
  THUMB_BG2,
  THUMB_BG3,
} from "../../AppConstants/AppConstants";

export const BackgroundPhotoComponent = ({
  activeImg,
  handleImageBackground,
}) => (
  <div className="d-flex align-content-center justify-content-center gap-4">
    <div
      className={`sg-bg-filter rounded-circle ${activeImg === BG1 && ACTIVE}`}
    >
      <img
        className={`background-img w-100 rounded-circle`}
        src={THUMB_BG1}
        alt="Snow"
        onClick={() =>
          handleImageBackground(BG1, IMG1, CURTAIN_UP_1, THEME1, bg_ga_title1)
        }
      />
    </div>
    <div
      className={`sg-bg-filter rounded-circle ${activeImg === BG2 && ACTIVE}`}
    >
      <img
        className="background-img w-100 rounded-circle"
        src={THUMB_BG2}
        alt="Forest"
        onClick={() =>
          handleImageBackground(BG2, IMG2, CURTAIN_UP_2, THEME2, bg_ga_title2)
        }
      />
    </div>
    <div
      className={`sg-bg-filter rounded-circle ${activeImg === BG3 && ACTIVE}`}
    >
      <img
        className="background-img w-100 rounded-circle"
        src={THUMB_BG3}
        alt="Mountains"
        onClick={() =>
          handleImageBackground(BG3, IMG3, CURTAIN_UP_3, THEME3, bg_ga_title3)
        }
      />
    </div>
  </div>
);

/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Button, Image, Modal, Row, Col } from "react-bootstrap";
import * as htmlToImage from "html-to-image";

import agreementPdf from "../../assets/agreementPdf/Prudential_NonUnionTalent_NonPay_Release.pdf";
import CirularCheckbox from "../../components/custom/CirularCheckbox";
import map from "../../assets/images/Billboard Map.jpg";

import {
  FESTIVAL_SITE_URL,
  WELCOMEPAGE,
} from "../../AppConstants/AppConstants";
import { getAccessToken } from "../../helpers";
import client from "../../services/axios";

import "./previewScreen.scss";
import returnIcon from "../../assets/images/icon-return.svg";
import shareIcon from "../../assets/images/icon-share.svg";
import backSiteArrow from "../../assets/images/festive-next-arrow.png";
import AppContext from "../../AppContext";
import Loader from "../../components/custom/Loader";

export const PreviewScreenContainer = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const billboardType = useContext(AppContext);
  const [showLoader, setShowLoader] = useState(true);
  //handle agreement click
  const handleAgreementClick = () => {
    if (typeof window !== "undefined") {
      window.gtag("event", "read_agreement");
    }
  };

  //handle to go home page
  const handleHomeClick = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "home_page");
    }
    window.history.pushState(null, null, "/staronplaybill");
    window.location.replace("/staronplaybill");
    // navigate(WELCOMEPAGE);
  }, [navigate]);

  //handle to open or close modal
  const [show, setShow] = useState(false);
  const toggleModal = useCallback(() => {
    setShow((state) => !state);
    if (typeof window !== "undefined") {
      window.gtag("event", "share_timesquare");
    }
  }, []);

  const [agreedToPublishOnBillboard, setAgreedToPublishOnBillboard] =
    useState(true);

  //handle to open or close modal
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltipModal = useCallback(() => {
    setShowTooltip((state) => !state);
    if (typeof window !== "undefined") {
      window.gtag("event", "show_info");
    }
  }, []);

  // handle image download
  // const handleDownloadClick = () => {
  //   const link = document.createElement("a");
  //   link.download = "filteredImg.svg";
  //   link.href = state.imageDataURL;
  //   link.click();
  // };

  const handleDownload = async () => {
    try {
      const response = await fetch(state.imageDataURL);

      if (!response.ok) {
        throw new Error("Failed to fetch image.");
      }

      const blob = await response.blob();
      const blobURL = window.URL.createObjectURL(blob);

      const anchorElement = document.createElement("a");
      anchorElement.href = blobURL;
      anchorElement.download = Math.floor(Math.random() * 10 + 1) + "preview";
      anchorElement.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
        anchorElement.remove();
      }, 100);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  const handleShareClick = async () => {
    //const files = imageRef.current;

    const img = document.getElementById("filteredImg");
    const selfieUrl = "http://pruselfie.com";
    console.log(selfieUrl);
    fetch(img.src)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], "playbill.png", blob);
        const files = [];
        files.push(file);
        console.log(files);
        if (navigator.share) {
          try {
            await navigator.share({
              files,
              title: "Prudential Selfie Generator",
              text: `Check out this cool Playbill Selfie Generator by Prudential ${selfieUrl}`,
              icon: `${window.location.origin}/favicon.png`,
            });
          } catch (error) {
            console.error("Error sharing image:", error);
          }
        } else {
          console.log("Web Share API not supported in this browser");
          handleDownload();
        }
      });
  };

  // handle image share via browser
  // const handleShareClick = async () => {
  //   if (typeof window !== "undefined") {
  //     window.gtag("event", "share_msg");
  //   }
  //   if (navigator.share) {
  //     navigator.share({
  //       url: `${window.location.origin}/staronplaybill`,
  //       title: "Curtain Up Playbill Selfie",
  //       text: `Hey, Check out this amazing site to create cool selfies`,
  //     });
  //   }
  // };

  function downloadImage(base64doc, toastId) {
    const canvas = document.createElement("canvas");
    const w = 1024;
    const h = 1024;
    const img_to_download = document.createElement("img");
    img_to_download.src = "data:image/png;base64," + base64doc;
    img_to_download.onload = async function () {
      canvas.setAttribute("width", w);
      canvas.setAttribute("height", h);
      const context = canvas.getContext("2d");
      context.drawImage(img_to_download, 0, 0, w, h);
      canvas.toDataURL("image/png");
      canvas.toBlob(async (blob) => {
        const { firstName, lastName, email } = state.userInfo || {};
        const formData = new FormData();
        formData.append("image", blob, Date.now() + ".png");
        formData.append("name", `${firstName} ${lastName}`);
        formData.append("email", email);
        const token = await getAccessToken();

        const response = await client.post(
          token,
          "/api/v1/selfie_app/publish_to_billboard",
          formData,
          {
            "Content-Type": "multipart/form-data",
          }
        );

        if (response.success) {
          return toast.success("Playbill cover sent successfully!", {
            id: toastId,
          });
        }

        toast.error(response?.message || "Failed to send selfie image!", {
          id: toastId,
        });


      });
    };
  }

  // handle image share on time square
  const handleShareOnTimeSquareClick = useCallback(async () => {
    let toastId;
    try {
      // close the modal
      toggleModal();

      // show loader
      toastId = toast.loading("sending...");

      let imageDataURL;
      for (var i = 0; i < 10; i++) {
        imageDataURL = await htmlToImage.toPng(document.getElementById("previewDiv"), {pixelRatio: 5});
      }
      const base64doc = imageDataURL.replace(/^data:image\/png;base64,/, "");
      downloadImage(decodeURIComponent(base64doc), toastId);

    } catch (error) {
      toast.error(error.message, { id: toastId });
    }
    if (typeof window !== "undefined") {
      window.gtag("event", "send_timesquare");
    }
  }, [state, toggleModal]);

  useEffect(() => {
    if (state.imageDataURL) {
      setShowLoader(false);
    }
  }, [state.imageDataURL]);

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section preview-page bg-secondary d-flex flex-column flex-1 align-items-center justify-content-center">
        <div
          className={`preview-image-box text-center mx-auto d-flex align-items-center justify-content-center ${
            billboardType?.billboardType ? "billboard-preview-image-box" : ""
          }`}
          id="previewDiv"
        >
          {!showLoader ? (
            <Image
              id="filteredImg"
              src={state.imageDataURL}
              alt="preview img"
              style={{ height: 400, width: "auto" }}
              className="preview-img"
            />
          ) : (
            <Loader
              zIndex={9999}
              loaderText="Your Playbill cover is processing!"
              customClasses="preview-loader text-lg text-primary"
            />
          )}
        </div>

        <div className="sg-edit-action-section d-flex flex-column gap-3 px-4 py-3">
          <h2 className="preview-screen-title text-white fw-semibold text-center mb-0">
            You’re a star!
          </h2>
          <h6 className="fw-semibold text-center">Tap & hold image to save!</h6>
          <div className="d-flex flex-column px-2 gap-3">
            <div className="d-flex justify-content-center gap-3">
              <Button
                variant="primary"
                className="fs-5 p-2 rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleHomeClick}
              >
                <Image
                  src={returnIcon}
                  alt="Return"
                  className="preview-action-icon"
                />
              </Button>
              {/* <Button
                onClick={handleDownloadClick}
                variant="primary"
                className="fs-5 p-2 rounded-circle d-flex align-items-center justify-content-center"
              >
                <Image
                  src={downloadIcon}
                  alt="Download"
                  className="preview-action-icon"
                />
              </Button> */}
              <Button
                variant="primary"
                className="fs-5 p-2 rounded-circle d-flex align-items-center justify-content-center"
                onClick={handleShareClick}
              >
                <Image
                  src={shareIcon}
                  alt="Share"
                  className="preview-action-icon"
                />
              </Button>
            </div>
            {billboardType?.billboardType && (
              <Row className="align-items-center">
                <Col xs={2}></Col>
                <Col xs={8} className="text-center">
                  <Button
                    variant="primary"
                    onClick={toggleModal}
                    className="fs-md fw-semibold px-4 py-2 rounded-pill ls-spacing text-center mx-auto"
                  >
                    Share in Time Square
                  </Button>
                </Col>
                <Col xs={2}>
                  <span className="d-inline-block">
                    <i
                      className="bi bi-info-circle fs-5"
                      onClick={toggleTooltipModal}
                      title="Click here"
                    ></i>
                  </span>
                </Col>
              </Row>
            )}
          </div>
          <div className="festive-link-wrap d-flex align-items-center justify-content-center py-1 text-center mb-0">
            <a
              className="d-inline-block fs-xs text-white fw-semibold"
              href={FESTIVAL_SITE_URL}
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.gtag("event", "festival_site");
                }
              }}
            >
              Access festival guide and broadway tickets giveaway
              <img
                src={backSiteArrow}
                alt="festive site arrow"
                className="arrow-img ms-2"
              />
            </a>
          </div>
          <Modal
            show={showTooltip}
            onHide={toggleTooltipModal}
            centered
            className="sg-modal sg-share-modal"
          >
            <Modal.Footer className=" border-0 gap-2 py-1">
              <Button
                variant="outline-primary"
                onClick={toggleTooltipModal}
                className="btn-close fs-md text-secondary fw-semibold rounded-pill m-0 "
              ></Button>
            </Modal.Footer>

            <Modal.Footer className="align-items-center justify-content-center border-0 gap-2 px-5 pb-2 pt-0">
              <img src={map} class="card-img-top w-100 m-0" alt="Map"></img>
            </Modal.Footer>

            <Modal.Body className="px-5 py-3">
              <p className="fs-md text-secondary fw-semibold text-center mb-0">
                With your consent your Playbill could be published on the Times
                Square billboard (2 Times Square, NY) during the Curtain Up
                Broadway Festival, September 08th - 10th 2023.
              </p>
            </Modal.Body>
          </Modal>

          <Modal
            show={show}
            onHide={toggleModal}
            centered
            className="sg-modal sg-share-modal"
          >
            <Modal.Body className="px-4 pt-4 pb-2">
              <p className="fs-md text-secondary fw-semibold text-center mb-2">
                This is an{" "}
                <a
                  href={agreementPdf}
                  target="_blank"
                  onClick={handleAgreementClick}
                >
                  agreement
                </a>{" "}
                for the generated image to appear on a Times Square Billboard at
                anytime during the Curtain Up Broadway Festival
              </p>
              <p className="fs-md text-secondary fw-semibold text-center mb-0">
                <div className="d-flex gap-2 justify-content-center">
                  <CirularCheckbox
                    isChecked={!agreedToPublishOnBillboard}
                    toggleIsChecked={() => {
                      setAgreedToPublishOnBillboard(
                        !agreedToPublishOnBillboard
                      );
                    }}
                  />
                  I agree
                </div>
              </p>
            </Modal.Body>
            <Modal.Footer className="align-items-center justify-content-center border-0 gap-2 px-4 pb-4 pt-0">
              <Button
                variant="outline-primary"
                onClick={toggleModal}
                className="fs-md text-secondary fw-semibold px-5 py-2 rounded-pill m-0"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleShareOnTimeSquareClick}
                disabled={agreedToPublishOnBillboard}
                className="fs-md fw-semibold px-5 py-2 rounded-pill m-0"
              >
                Send
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Button, Form } from "react-bootstrap";
import {
  BLACKANDWHITE,
  filter_ga_title1,
  filter_ga_title2,
  filter_ga_title3,
  NONE,
  VIBRANT,
} from "../../AppConstants/AppConstants";

export const PhotoFilterComponent = ({
  handleRotate,
  activeFilter,
  handleFiltreApplication,
}) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center gap-3 mb-2">
        <Button
          variant="info"
          className={`fs-md fw-semibold rounded-pill border-0 ${
            activeFilter === BLACKANDWHITE ? "active" : ""
          }`}
          onClick={() =>
            handleFiltreApplication(BLACKANDWHITE, filter_ga_title1)
          }
        >
          B&W
        </Button>
        <Button
          variant="info"
          className={`fs-md fw-semibold rounded-pill border-0 ${
            activeFilter === VIBRANT ? "active" : ""
          }`}
          onClick={() => handleFiltreApplication(VIBRANT, filter_ga_title2)}
        >
          Vibrant
        </Button>
        <Button
          variant="info"
          className={`fs-md fw-semibold rounded-pill border-0 ${
            activeFilter === NONE ? "active" : ""
          }`}
          onClick={() => handleFiltreApplication(NONE, filter_ga_title3)}
        >
          None
        </Button>
      </div>
      <div className="d-flex align-items-center gap-1">
        <Button variant="link" className="fs-4 px-2 py-1 fw-bold text-white">
          <i className="bi bi-arrow-clockwise"></i>
        </Button>
        <Form.Range
          type="range"
          min={-30}
          max={30}
          defaultValue={0}
          step={1}
          id="rotateRange"
          onChange={handleRotate}
          className="custom-range w-100"
        />
        <Button variant="link" className="fs-4 px-2 py-1 fw-bold text-white">
          <i className="bi bi-arrow-counterclockwise"></i>
        </Button>
      </div>
    </>
  );
};

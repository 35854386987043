import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import WelcomeScreenComponent from "./welcomeScreenComponent";

import {
  BILLBOARDTYPE,
  CAMERA,
  INSTRUCTION,
  PROMOTION,
} from "../../AppConstants/AppConstants";

import prudentialLogo from "../../assets/images/prudential-curtainUp-logo.png";
import { getAccessToken, isEventLive } from "../../helpers";
import AppContext from "../../AppContext";
import client from "../../services/axios";

export const WelcomeScreenContainer = () => {
  const navigate = useNavigate();

  const billboardType = useContext(AppContext);
  const { setBillboardType } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);

  //Handle to open instruction page after click on get started button of welcome page
  const handleOnClick = useCallback(() => {
    // if event is live go to promotion page
    if (typeof window !== "undefined") {
      window.gtag("event", "wl_getstarted");
    }
    // if (billboardType?.billboardType) {
    //   return navigate(PROMOTION);
    // }

    navigate(INSTRUCTION);
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const token = await getAccessToken();
        const response = await client.get(
          token,
          "api/v1/selfie_app/event_flow"
        );
        if (response.data.eventFlow === BILLBOARDTYPE) {
          setBillboardType(true);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("There was an error fetching the data", error);
      }
    };

    fetchData();
  }, [setBillboardType]);

  return (
    <WelcomeScreenComponent
      prudentialLogo={prudentialLogo}
      handleOnClick={handleOnClick}
      isLoading={isLoading}
    />
  );
};

import { CapturePhotoContainer } from "../Modules/capturePhoto/capturePhotoContainer";
import { ConfirmationScreenContainer } from "../Modules/confirmationScreen/confirmationScreenContainer";
import { InstructionScreenContainer } from "../Modules/instructionScreen/instructionScreenContainer";
import { OpenCameraContainer } from "../Modules/openCamera/openCameraContainer";
import { PreviewScreenContainer } from "../Modules/previewScreen/previewScreenContainer";
import { PromotionScreenContainer } from "../Modules/promotionScreen/promotionScreenContainer";
import { UserInfoContainer } from "../Modules/userInfo/userInfoContainer";
import { WelcomeScreenContainer } from "../Modules/welcomeScreen/welcomeScreenContainer";

import bg1 from "../assets/images/theme-bg-gray.png";
import bg2 from "../assets/images/theme-bg-woods.png";
import bg3 from "../assets/images/theme-bg-pink-clouds.png";

import thumbBg1 from "../assets/images/theme-bg-gray-small.jpeg";
import thumbBg2 from "../assets/images/theme-bg-woods-small.jpeg";
import thumbBg3 from "../assets/images/theme-bg-pink-clouds-small.jpeg";

//Background title constants
import curtain_up_1 from "../assets/images/theme.png";
import curtain_up_2 from "../assets/images/theme.png";
import curtain_up_3 from "../assets/images/theme.png";
import { OfficialRulesComponent } from "../Modules/sharedComponents/officialRulesComponent";
import { PrivacyPolicyComponent } from "../Modules/sharedComponents/privacyPolicyComponent";
import { ReleaseComponent } from "../Modules/sharedComponents/releaseComponent";
import { TermsComponent } from "../Modules/sharedComponents/termsComponent";
import { Term } from "../Modules/sharedComponents/term";

//api constants
export const REMOVEBG_API_PATH = `https://api.remove.bg/v1.0/removebg`;
export const USER_INFO_API_PATH = "/api/v1/site_users/selfie";
export const UPLOAD_FILE_API_PATH = "/api/v1/file/upload";

//Grid constant
export const GRID_SIZE = 420;

export const FIRST = 1;
export const SECOND = 2;
export const THIRD = 3;

//background theme constants
export const CURTAIN_UP_1 = curtain_up_1;
export const CURTAIN_UP_2 = curtain_up_2;
export const CURTAIN_UP_3 = curtain_up_3;

//Remove bg api key
export const REMOVEBG_API_KEY = process.env.REACT_APP_REMOVEBG_API_KEY;

//Photo filter constants
export const BACKGROUND = "Background";
export const PHOTO = "Photo";
export const TEXT = "Text";

//constants for image-filter
export const BLACKANDWHITE = "black-white-filter";
export const VIBRANT = "vibrant-filter";
export const NONE = "none-filter";

//Routes constants
export const WELCOMEPAGE = "/";
export const INSTRUCTION = "/instructions";
export const CAMERA = "/camera";
export const CAPTUREDPHOTO = "/captured-photo";
export const PROMOTION = "/promotion";
export const PREVIEW = "/preview";
export const CONFIRMSCREEN = "/confirmation";
export const USERINFO = "/user-info";
export const APP_BASE_ROUTE = "/staronplaybill";
export const OFFICIAL_RULES = "/official-rules";
export const PRIVACY_POLICY = "/privacy-policy";
export const RELEASE = "/release";
export const TERMS = "/terms";
export const TERMS_PAGE = "/terms-page"

export const ROUTES = [
  {
    path: WELCOMEPAGE,
    element: <WelcomeScreenContainer />,
  },
  {
    path: INSTRUCTION,
    element: <InstructionScreenContainer />,
  },
  {
    path: CAMERA,
    element: <OpenCameraContainer />,
  },
  {
    path: CAPTUREDPHOTO,
    element: <CapturePhotoContainer />,
  },
  {
    path: PROMOTION,
    element: <PromotionScreenContainer />,
  },
  {
    path: PREVIEW,
    element: <PreviewScreenContainer />,
  },
  {
    path: CONFIRMSCREEN,
    element: <ConfirmationScreenContainer />,
  },
  {
    path: USERINFO,
    element: <UserInfoContainer />,
  },
  {
    path: OFFICIAL_RULES,
    element: <OfficialRulesComponent />,
  },
  {
    path: PRIVACY_POLICY,
    element: <PrivacyPolicyComponent />,
  },
  {
    path: RELEASE,
    element: <ReleaseComponent />,
  },
  {
    path: TERMS,
    element: <TermsComponent />,
  },
  {
    path: TERMS_PAGE,
    element: <Term />,
  },
];
//privacyPolicyComponents
//Class name constants
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const BG1 = "bg1";
export const BG2 = "bg2";
export const BG3 = "bg3";

//image url constant
export const IMG1 = bg1;
export const IMG2 = bg2;
export const IMG3 = bg3;

export const THUMB_BG1 = thumbBg1;
export const THUMB_BG2 = thumbBg2;
export const THUMB_BG3 = thumbBg3;

export const bg_ga_title1 = "bg_gray";
export const bg_ga_title2 = "bg_woods";
export const bg_ga_title3 = "bg_pink";
export const filter_ga_title1 = "black_white_filter";
export const filter_ga_title2 = "vibrant_filter";
export const filter_ga_title3 = "none_filter";

export const BUTTONLIST = [BACKGROUND, TEXT, PHOTO];

export const THEME1 = "_variation-type1";
export const THEME2 = "_variation-type2";
export const THEME3 = "_variation-type3";

export const DAZZLING = "Dazzling";
export const BRILLIANT = "Brilliant , Stunning";
export const CLASSIC = "Instant Classic";

// api constants
export const SELFIE_SITE = "SELFIE_SITE";

// external sites url
export const FESTIVAL_SITE_URL = process.env.REACT_APP_FESTIVAL_APP; // will change for production

// event start and end date time
export const EVENT_START_DATE = "2023/07/27"; // YYYY/MM/DD
export const EVENT_START_TIME = "00:00"; // 24 hour format HH:MM
export const EVENT_END_DATE = "2024/07/29"; // YYYY/MM/DD
export const EVENT_END_TIME = "18:00"; // 24 hour format HH:MM

// remove bg errors
export const UNKNOWN_FOREGROUND = "unknown_foreground";

//Other constants
export const capture = "capture";
export const upload = "upload";

export const DEFAULTTYPE = "DefaultType";
export const BILLBOARDTYPE = "BILLBOARD_FLOW";

export const wc_page_billboard_content =
  "Put yourself on the Playbill front cover and become an instant \
Broadway Icon.";
export const wc_page_default_content =
  "Put yourself on the Playbill front cover and become an instant \
Broadway Icon.";
export const userInfo_billboard_content =
  "Fill in the information below to receive your personalized \
Playbill and see yourself in";
export const userInfo_default_content =
  "Fill in the information below to receive your personalized \
Playbill.";
export const MAX_CHAR_LENGTH = 12

import { Button, Image } from "react-bootstrap";

const ConfirmationScreenComponent = ({
  handleNextClick,
  handleRecaptureClick,
  croppedImg,
  state,
}) => {
  return (
    <>
      <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
        <div className="sg-content-section bg-secondary d-flex flex-column flex-1 position-relative scroll-y">
          <div
            id="container"
            className="d-flex flex-column align-items-center flex-1"
          >
            <Image
              src={croppedImg}
              alt="captured photo"
              className="w-100 h-100 object-fit-contain"
              id="uploadedImg"
            />
          </div>
          <div className="sg-edit-action-section d-flex align-items-center justify-content-between p-4">
            <Button
              variant="info"
              className="fs-md fw-semibold rounded-pill px-4 py-2 border-0"
              onClick={handleRecaptureClick}
            >
              {state?.upload ? "Back" : "Retake"}
            </Button>
            <Button
              variant="primary"
              className="fs-md fw-semibold rounded-pill px-4 py-2 border-0"
              onClick={handleNextClick}
            >
              Accept
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmationScreenComponent;

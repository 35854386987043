import { Button, Image } from "react-bootstrap";

import leftArrow from "../../assets/images/arrow-left-icon.svg";
import { useNavigate } from "react-router-dom";
import { TERMS } from "../../AppConstants/AppConstants";

export const PrivacyPolicyComponent = () => {
  const navigate = useNavigate();

  //handle to go to previous page
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleTermsPage = () => {
    console.log('term')
    navigate(TERMS);
  }

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
      <div className="sg-content-section d-flex flex-column flex-1 position-relative scroll-y py-5">
        {/* <HeaderComponent /> */}
        <div className="terms-content-wrap d-flex flex-column flex-1 scroll-y px-3 pb-5">
          <h2 className=" fw-bold mb-3">
            PRIVACY POLICY
          </h2>
          <p><strong>TERMS AND CONDITIONS FOR CONTENT SUBMISSION, RELEASE AND MARKETING SOLICITATIONS</strong></p>
          {/* <p><strong>MARKETING SOLICITATIONS</strong></p> */}
          <p>As part of my participation in the augmented reality website known as the A/R Experience (“Website”) by taking a selfie, entering my details for the player card and making &nbsp;use of any filters and supplying any other information to the A/R experience website (“User Content”)</p>
          <p>For good and valuable consideration, in exchange for participating in the A/R Experience and submitting User Content, I agree to these terms and condition (the “Agreement”) as follows:</p>
          <p>I hereby grant to <strong>The Prudential Insurance Company of America</strong> (“Prudential”) and , either of its agents and others working on Prudential’s behalf (collectively, the “Licensed Parties”), the irrevocable right and permission to use, distribute, display, publish, exhibit, digitize, broadcast, reproduce, license, sublicense, transfer and otherwise exploit my name, image, recorded performance, voice, likeness, statements and actions, and any materials based or derived from these items (the “Work”), and to give others the right to make such use, in any manner that the Licensed Parties choose and in any media now known or hereinafter invented, throughout the world in perpetuity, for the purpose of advertising, promoting or publicizing Prudential’s products and services and for any other lawful purpose.</p>
          <p>I acknowledge that neither Prudential nor any Licensed Party is required to compensate or credit me or anyone else in connection with this Agreement or any such use. Notwithstanding the foregoing, in the event the Work is used in such a manner as to be subject to the jurisdiction of any SAG-AFTRA collective bargaining agreement, I agree to execute a standard scale contract and accept minimum scale compensation for such use.</p>
          <p>I agree that any and all materials produced and/or resulting from this Agreement shall be owned exclusively by Prudential and I have no rights, title, or interest in or to such materials. If for any reason any of the foregoing does not vest in Prudential, I hereby assign to Prudential all right, title and interest in and to the same. If I should receive any print, negative, or other copy thereof, I shall not use nor authorize its use by anyone else.  I hereby waive my right to inspect or approve the Work, the User Content once I submit it to the Website or any materials produced hereunder.  Nothing in this Agreement requires the Licensed Parties or its licensees to make any use of the Work, the Program or any materials produced hereunder, or the rights granted herein.</p>
          <p>I represent and warrant that (i) I have not given any third party the exclusive right to use my name, image, voice, likeness, or performance, and I am free to sign this Agreement without conflict with any existing commitment on my part; and (ii) I am solely responsible for any government employment tax, insurance and immigration obligations that may results or arise from the use of my appearance or the Work hereunder.
            I release the Licensed Parties, any company or person employed by or related to the Licensed Parties, and any person or company that obtains any rights from the Licensed Parties described above, from all liabilities and claims, whether known or unknown, asserted or non-asserted, that relate in any way to the rights I have given to the Licensed Parties above or my participation in the User Content including, but not limited to, those arising from or related to editorial acts, copyright, defamation, misrepresentation, faulty reproductions, violation of right of publicity or invasion of privacy.
          </p>
          <p>I shall not make or authorize any public statement concerning this Agreement, the User Content or the Licensed Parties without the prior consent written of the Licensed Parties in each instance.</p>
          <p>If I submit my User Content for consideration to be used on the Prudential Billboard located at 2 Times Square, New York, NY 10020 or otherwise at Curtain Up Broadway Festival presented by Prudential you acknowledge that your User Content may be posted publicly both at the Prudential Billboard,  on the Licensed Parties website and/or elsewhere on the Internet as determined by the Licensed Parties and the Playbill and that the Licensed Parties are not obligated to post or use your User Content.</p>
          <p>I will regard and preserve in strict confidence all information related to the business, accounting, personnel, products and services of Licensed Parties, its affiliates and third parties with whom they do business (“Confidential Information”) that is not otherwise publicly available, which may be obtained by me from any source as a result of this Agreement. I will not, without first obtaining the Licensed Parties’ prior written consent, disclose to any person or use for my own benefit or for the benefit of any third party any Confidential Information.</p>
          <p>If I have entered my email and selected the option to receive more information from Prudential, you consent to Prudential contacting you for marketing purposes. You may optout at any time by clicking ‘unsubscribe’ within any of the marketing emails that Prudential now or in the future sends you. See Prudential’s privacy policy at  &nbsp;<a href='https://rocktheexperience.com/terms.html' target="_blank" className="text-primary">rocktheexperience.com/terms.html</a></p>
          <p>By using the features of the Website and submitting User Content to the Website, I agree to abide by and be bound by these Terms and Conditions, the Privacy Policy for the Website which is available at &nbsp;<a href='https://rocktheexperience.com/terms.html' target="_blank" className="text-primary">rocktheexperience.com/terms.html</a> &nbsp;.By submitting User Content to the Website, I agree: (a) that the Licensed Parties will have no liability whatsoever for any injuries, losses, harm, damage, cost, or expense, including, without limitation, any personal injury (including from COVID-19 or its variants), arising from or in connection with participation, directly or indirectly, in the Website or any Website-related activity; (b) under no circumstances will Website participants be permitted to obtain awards for, and participants hereby waive all rights to claim, punitive, incidental, consequential, or any other damages, other than for actual out-of-pocket expenses excluding attorneys’ fees and court costs; (c) all causes of action arising out of or connected with this Website shall be resolved individually, without resort to any form of class action; and (d) any and all claims, judgments, and award shall be limited to actual out-of-pocket costs incurred, excluding attorneys’ fees and court costs.
            The invalidity or unenforceability of any provision of these Terms and Conditions shall not affect the validity or enforceability of any other provision. In the event that any provision is determined to be invalid or otherwise unenforceable or illegal, these Terms and Conditions shall otherwise remain in effect and shall be construed in accordance with their terms as if the invalid or illegal provision were not contained herein.</p>
          <p>This Agreement contains the entire understanding between the parties regarding the subject matter hereof, supersedes all prior understandings and may not be modified except by a writing signed by both parties. This Agreement shall be construed in accordance with the laws of the State of New Jersey, without regard to any conflict of laws.</p>
          <p><strong>I fully understand the contents of this Agreement and that the Licensed Parties</strong> <strong>are relying on the rights that I have granted to it, and I voluntarily agree to the terms and conditions of the Agreement.&nbsp; I am eighteen (18) years of age or older any by my submission of the User Content and clicking ‘Agree and Finish” I am bound by the terms of the Agreement. </strong></p>

        </div>
        <div className="back-fix-btn w-100 p-3 d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            className="fs-md text-uppercase fw-bold rounded-0 py-1 px-3 text-dark text-decoration-none"
            onClick={handleGoBack}
          >
            <Image src={leftArrow} alt="right arrow" color="#000000" className="me-2 text-black" />
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

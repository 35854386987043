import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PromotionScreenComponent } from "./promotionScreenComponent";

import { INSTRUCTION } from "../../AppConstants/AppConstants";

import "./promotionScreen.scss";
import prudentialLogo from "../../assets/images/prudential-curtainUp-logo.png";

export const PromotionScreenContainer = () => {
  const navigate = useNavigate();

  //Handle to open instruction page after click on get started button of promotion page
  const handleOnClick = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "promo_continue");
    }

    navigate(INSTRUCTION);
  }, [navigate]);

  return (
    <PromotionScreenComponent
      prudentialLogo={prudentialLogo}
      handleOnClick={handleOnClick}
    />
  );
};

import React from "react";
import Webcam from "react-webcam";
import { Button, Image } from "react-bootstrap";

import cameraIcon from "../../assets/images/icon-camera.svg";
import uploadIcon from "../../assets/images/upload-icon.svg";

import "./openCamera.scss";

export const OpenCameraComponent = ({
  handleCapturePhoto,
  handleGoBack,
  webcamRef,
  handleOnClick,
  handleFileUpload,
  showError
}) => {
  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section bg-secondary d-flex flex-column flex-1">
        <div
          className="error_msg pt-5 text-danger text-center"
          style={{ display: "none" }}
          id="error_div"
        >
          <h4 id="error_msg" className="text-danger text-center">
            {showError}
          </h4>
          <Button onClick={handleGoBack}>Go to Home</Button>
        </div>
        <div className="d-flex flex-column flex-1">
          <div style={{ height: "100%", position: "relative" }}>
            <Webcam
              mirrored={true}
              audio={false}
              ref={webcamRef}
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
          <div className="sg-edit-action-section d-flex flex-column align-items-center justify-content-center p-4">
            <div className="camera-action-btn-wrap d-flex gap-5 align-items-center">
              <Button
                id="upload-icon"
                variant="primary"
                className="camera-action-btn rounded-circle p-4"
                onClick={handleOnClick}
              >
                <Image src={uploadIcon} alt="Upload" />
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  id="uploadFile"
                  onChange={handleFileUpload}
                />
              </Button>
              <Button
                id="camera-icon"
                variant="primary"
                className="camera-action-btn rounded-circle p-4"
                onClick={handleCapturePhoto}
              >
                <Image src={cameraIcon} alt="Camera" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

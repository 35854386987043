import React, { useCallback } from "react";

import { BackgroundPhotoComponent } from "./backgroundPhotoComponent";

export const BackgroundPhotoContainer = ({
  setSelectedImg,
  setActiveImg,
  activeImg,
  setThemeClass,
  setIsCustom,
}) => {
  //handle to change background image
  const handleImageBackground = useCallback(
    (imageName, backgroundUrl, title, theme, ga_title) => {
      if (typeof window !== "undefined") {
        window.gtag("event", ga_title);
      }
      setSelectedImg({ background: backgroundUrl, title: title });
      setActiveImg(imageName);
      setThemeClass(theme);
      setIsCustom(false);
    },
    [setActiveImg, setIsCustom, setSelectedImg, setThemeClass]
  );

  return (
    <BackgroundPhotoComponent
      activeImg={activeImg}
      handleImageBackground={handleImageBackground}
    />
  );
};

import html2canvas from "html2canvas";

import client from "../services/axios";
import {
  EVENT_END_DATE,
  EVENT_END_TIME,
  EVENT_START_DATE,
  EVENT_START_TIME,
} from "../AppConstants/AppConstants";
import { convertDateToTimeZone } from "./date";

export const htmlToImage = async (
  previewElement,
  options = { download: true }
) => {
  try {
    const canvas = await html2canvas(previewElement, {
      allowTaint: true,
      useCORS: true,
    });

    const data = canvas.toDataURL("image/jpeg");

    // download the image when this flag is true
    if (options.download) {
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = "image.jpeg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }

    // download the image when this "file" options flag is true
    if (options.file) {
      // Create a Blob object from the data URL
      const blob = await (await fetch(data)).blob();

      // Create a File object with the blob
      const file = new File([blob], "image.jpeg", { type: blob.type });

      return { success: true, file };
    }
  } catch (error) {
    return { success: false, message: error.message, error };
  }
};

export const getAccessToken = async () => {
  const response = await client.post(null, "api/v1/auth/login", {
    email: process.env.REACT_APP_ADMIN_USER,
    password: process.env.REACT_APP_ADMIN_PASS,
  });
  return response?.success ? response.data.token : null;
};

export const getDateTimeStamp = (date = new Date()) => {
  return `${date.toLocaleDateString()}_${date.toLocaleTimeString()}_${date.valueOf()}`;
};

export const base64ToBlob = async (base64String) => {
  if (!base64String) return;

  const res = await fetch(base64String);
  const blob = await res.blob();
  blob.name = `${getDateTimeStamp()}.jpeg`;

  return blob;
};

export const downloadImage = async (imageDataURL) => {
  const link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = imageDataURL;
    link.download = "image.jpeg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(imageDataURL);
  }
};

export const isEventLive = () => {
  const startDateTime = convertDateToTimeZone(
    new Date(`${EVENT_START_DATE} ${EVENT_START_TIME}`)
  );
  const endDateTime = convertDateToTimeZone(
    new Date(`${EVENT_END_DATE} ${EVENT_END_TIME}`)
  );
  const currentDateTime = convertDateToTimeZone(new Date());

  return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
};

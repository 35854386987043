import React from "react";
import { Button, Image } from "react-bootstrap";

import promotionDetail from "../../assets/images/promotion-details.png";

import "./promotionScreen.scss";

export const PromotionScreenComponent = ({ prudentialLogo, handleOnClick }) => {
  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section promotion-screen-section bg-secondary d-flex flex-column align-items-center justify-content-center flex-1">
        <Image
          src={prudentialLogo}
          alt="Prudential Logo"
          className="w-100 mb-5"
        />
        <div className="event-details-box mb-5">
          <h5 className="fw-semibold text-center text-uppercase mb-1">
            SEPTEMBER 8-10
          </h5>
          <h5 className="fw-semibold text-center text-uppercase mb-0">
            FREE eVENT-IN TIMES SQUARE
          </h5>
          <p className="fs-xs text-center mb-0">
            Between 45th & 49th Streets in New York City
          </p>
        </div>
        <Image
          src={promotionDetail}
          alt="promotion detail"
          className="w-100 mb-5"
        />
        <Button
          variant="primary"
          className="fs-md fw-semibold px-5 py-2 rounded-pill ls-spacing mt-4"
          onClick={handleOnClick}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

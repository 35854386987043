import React from "react";
import { Button, Card, Image } from "react-bootstrap";

import camera from "../../assets/images/camera-photo.png";
import bgOptions from "../../assets/images/bg-variations.png";

import "./instructionScreen.scss";

const InstructionScreenComponent = ({ prudentialLogo, handleOnClick }) => {
  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section instruction-screen-section bg-secondary d-flex flex-column flex-1">
        <div className="d-flex flex-column align-items-center justify-content-center flex-1 gap-4">
          <Card className="sg-card bg-white w-100 mb-3 border-0">
            <Card.Body className="text-center d-grid gap-2">
              <Card.Title className="sg-card-title fw-semibold text-center text-secondary mb-0">
                Instructions
              </Card.Title>
              <p className="fs-md fw-semibold text-secondary mb-0">
                Step 1: Take a selfie
              </p>
              <span className="fs-sm text-secondary">For best results hold steady at arms length, in well-lit environment</span>
              <Image
                src={camera}
                alt="camera"
                className="camera-icon mx-auto"
              />
              <p className="fs-md fw-semibold text-secondary mb-0">
                Step 2: Choose your background
              </p>
              <Image
                src={bgOptions}
                alt="Background Options"
                className="bg-options-img mx-auto"
              />
              <p className="fs-md fw-semibold text-secondary mb-0">
                Step 3: Give yourself a rave review, and share with friends!
              </p>
            </Card.Body>
          </Card>
          <Button
            variant="primary"
            className="fs-md fw-semibold rounded-pill ls-spacing get-started-btn"
            onClick={handleOnClick}
          >
            Take a Selfie
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InstructionScreenComponent;

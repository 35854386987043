import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import {
  CONFIRMSCREEN,
  WELCOMEPAGE,
  capture,
  upload,
} from "../../AppConstants/AppConstants";
import { OpenCameraComponent } from "./openCameraComponent";

export const OpenCameraContainer = () => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);

  const [showError, setShowError] = useState("");

  //handle to go back if there is any error while accesing camera
  const handleGoBack = useCallback(() => {
    navigate(WELCOMEPAGE);
  }, [navigate]);

  const handleCapturePhoto = useCallback(() => {
    if (typeof window !== "undefined") {
      window.gtag("event", "capture_photo");
    }

    // const imageSrc = webcamRef.current.getScreenshot();
    const videoElement = webcamRef.current.video;
    // Create a canvas element to manipulate the captured image
    const canvas = document.createElement("canvas");
    const aspectRatio = videoElement.clientWidth / videoElement.clientHeight;
    // Calculate the dimensions for the cropped image while maintaining the aspect ratio
    let newWidth, newHeight;
    if (videoElement.videoWidth / videoElement.videoHeight > aspectRatio) {
      newWidth = videoElement.videoHeight * aspectRatio;
      newHeight = videoElement.videoHeight;
    } else {
      newWidth = videoElement.videoWidth;
      newHeight = videoElement.videoWidth / aspectRatio;
    }

    // Calculate the position to crop the image from the center
    const x = (videoElement.videoWidth - newWidth) / 2;
    const y = (videoElement.videoWidth - newHeight) / 2;

    // Set the canvas dimensions to match the new aspect ratio
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the cropped image on the canvas
    const ctx = canvas.getContext("2d");

    ctx.translate(newWidth, 0);
    ctx.scale(-1, 1);

    ctx.drawImage(
      videoElement,
      x,
      0,
      newWidth,
      newHeight,
      0,
      0,
      newWidth,
      newHeight
    );

    // Convert the canvas data to a base64 image
    const imageSrc = canvas.toDataURL();

    //Used to pass captured image
    navigate(CONFIRMSCREEN, {
      state: {
        imageSrc,
        capture,
      },
    });
  }, [navigate]);

  //Trigger the file upload on button click
  const handleOnClick = () => {
    if (typeof window !== "undefined") {
      window.gtag("event", "upload_photo");
    }
    document.getElementById("uploadFile").click();
  };

  //handle to show uploaded file
  const handleFileUpload = (e) => {
    const file = e.target.files[0];

    // validate if selected file is only image format
    const pattern = /image-*/;

    if (file.type.includes("gif")) {
      return toast.error("This image format is not allowed!");
    }

    if (!file.type.match(pattern)) {
      return toast.error("Only image are allowed!");
    }

    let imageSrc = URL.createObjectURL(e.target.files[0]);
    //Used to pass captured image
    navigate(CONFIRMSCREEN, {
      state: {
        imageSrc,
        upload,
      },
    });
  };

  return (
    <OpenCameraComponent
      handleCapturePhoto={handleCapturePhoto}
      handleGoBack={handleGoBack}
      webcamRef={webcamRef}
      handleOnClick={handleOnClick}
      handleFileUpload={handleFileUpload}
      showError={showError}
    />
  );
};

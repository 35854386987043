import React from "react";
import { Button, Image, Spinner } from "react-bootstrap";

import "./welcomeComponent.scss";
import { useContext } from "react";
import { wc_page_billboard_content, wc_page_default_content } from "../../AppConstants/AppConstants";
import AppContext from "../../AppContext";

const WelcomeScreenComponent = ({ prudentialLogo, handleOnClick ,isLoading}) => {

  const billboardType = useContext(AppContext);


  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center">
      <div className="sg-content-section welcome-screen-section bg-secondary d-flex flex-column align-items-center justify-content-center flex-1">
        <Image
          src={prudentialLogo}
          alt="Prudential Logo"
          className="prudential-logo-wrap w-100"
        />
        <div className="box-wrapper">
          <h1 className="box-heading fw-semibold text-center mb-3">
            It’s your turn to be a star!
          </h1>
          <h5 className="box-subheading fw-semibold text-center mb-0">
            {isLoading ? (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>):
            (billboardType?.billboardType ? wc_page_billboard_content : wc_page_default_content)}
          </h5>
        </div>
        <Button
          variant="primary"
          disabled={isLoading}
          className="fs-md fw-semibold rounded-pill ls-spacing get-started-btn"
          onClick={handleOnClick}
        >
          Get started
        </Button>
      </div>
    </div>
  );
};

export default WelcomeScreenComponent;

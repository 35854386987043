import { useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import UserInfoComponent from "./userInfoComponent";

import { PREVIEW, USER_INFO_API_PATH } from "../../AppConstants/AppConstants";
import { base64ToBlob, getAccessToken, isEventLive } from "../../helpers";

import client from "../../services/axios";
import AppContext from "../../AppContext";

export const UserInfoContainer = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [disableAgreeBtn, setDisableAgreeBtn] = useState(true);
  const [showLoader, setShowLoader] = useState(true);
  const { firstName, setFirstName } = useContext(AppContext);
  const { lastName, setLastName } = useContext(AppContext);
  const { email, setEmail } = useContext(AppContext);
  const { zipCode, setZipCode } = useContext(AppContext);
  const { prudentialMarketingAccepted, setPrudentialMarketingAccepted } =
    useContext(AppContext);

  const { phoneNumber, setPhoneNumber } = useContext(AppContext);
  const { phoneMarketingAccepted, setPhoneMarketingAccepted } =
    useContext(AppContext);

  //handle to go preview screen after click on agree & finish button
  const handleToGoPreviewScreen = useCallback(
    (userInfo) => {
      let imageDataURL = state.imageDataURL;
      navigate(PREVIEW, {
        state: {
          imageDataURL,
          userInfo,
        },
      });
    },
    [navigate, state.imageDataURL]
  );

  const resetUserInfo = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setZipCode("");
    setPhoneNumber("");
    setPrudentialMarketingAccepted(false);
    setPhoneMarketingAccepted(false);
  };

  //handle to submit details
  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (typeof window !== "undefined") {
      window.gtag("event", "agree_finish");
    }
    const toastId = toast.loading("Creating your Playbill");
    const accessToken = await getAccessToken();
    const payload = {
              firstName,
              lastName,
              email,
              prudentialMarketingAccepted,
              phoneNumberMarketingOpted: phoneMarketingAccepted,
              zipCode,
              imageURL: state.imageDataURL,
              imageS3Key: "NA",
              imageFileName: "NA",
              imageS3BucketName: "NA",
     };

    const response = await client.post(accessToken, USER_INFO_API_PATH, {
          ...payload,
          ...(phoneNumber ? { phoneNumber } : {}),
        });
    resetUserInfo()
    if (response.success) {
      handleToGoPreviewScreen({ firstName, lastName, email });
      return toast.success("Saved Successfully !", { id: toastId });
    }

    toast.error(response.message || "Something went wrong!", {
      id: toastId,
    });
  };

  const _isEventLive = isEventLive();

  //handle to disable enable agree finish button
  useEffect(() => {
    if (state.imageDataURL) {
      setShowLoader(false);
    }
    if (firstName === "" || lastName === "" || email === "" || zipCode === "") {
      setDisableAgreeBtn(true);
    } else {
      setDisableAgreeBtn(false);
    }
  }, [email, firstName, lastName, state.imageDataURL, zipCode]);

  return (
    <UserInfoComponent
      showLoader={showLoader}
      userInfoImage={state.imageDataURL}
      firstName={firstName}
      lastName={lastName}
      email={email}
      phoneNumber={phoneNumber}
      phoneMarketingAccepted={phoneMarketingAccepted}
      prudentialMarketingAccepted={prudentialMarketingAccepted}
      setPrudentialMarketingAccepted={setPrudentialMarketingAccepted}
      zipCode={zipCode}
      setLastName={setLastName}
      setFirstName={setFirstName}
      setEmail={setEmail}
      setPhoneNumber={setPhoneNumber}
      setZipCode={setZipCode}
      handleSubmit={handleSubmit}
      isEventLive={_isEventLive}
      disableAgreeBtn={disableAgreeBtn}
      setPhoneMarketingAccepted={setPhoneMarketingAccepted}
    />
  );
};

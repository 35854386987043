import { Button, Image } from "react-bootstrap";

import leftArrow from "../../assets/images/arrow-left-icon.svg";
import { useNavigate } from "react-router-dom";

export const TermsComponent = () => {
  const navigate = useNavigate();

  //handle to go to previous page
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="sg-main-wrap d-flex flex-column flex-1 align-items-center scroll-y">
      <div className="sg-content-section d-flex flex-column flex-1 position-relative scroll-y py-5">
        {/* <HeaderComponent /> */}
        <div className="terms-content-wrap d-flex flex-column flex-1 scroll-y px-3 pb-5">
          <p>PRIVACY NOTICE</p>
          <p>Prudential values your trust. We respect the privacy of your personal information and take our responsibility to protect it seriously. This privacy notice is provided on behalf of the Prudential companies listed at the end of this notice (“Prudential”) and applies to all participants in the A/R Experience. This notice describes how we treat the information we receive about you, including the ways in which we will share your personal information within Prudential and your right to opt out of such sharing.</p>
          <p>Protecting Your Personal Information</p>
          <p>Prudential maintains physical, electronic, and procedural safeguards to protect your personal information. The people who are authorized to have access to your personal information need it to do their jobs, and we require them to keep that information secure and confidential.</p>
          <p>Consent to Collection, Processing, and Transfer of Personal Information</p>
          <p>The A/R Experience is governed by and operated in accordance with the laws of the United States. Prudential makes no representation that the A/R Experience is governed by or operated in accordance with the laws of any other nation. If you are located outside of the United States, please be aware that information we collect, including Personal Information, may be transferred to, processed, stored, and used in the United States. Under United States law, your Personal Information may be subject to access requests from governments, courts, or law enforcement in the United States. By submitting your name and photograph to the A/R Experience, you consent to your personal information being transferred to, processed, stored, and used in the United States.</p>
          <p>Personal Information We Collect</p>
          <p>Prudential collects your personal information when you submit your A/R Experience entry on the A/R experience website. This information includes, for example:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Photograph</li>
            <li>Age</li>
            <li>Gender</li>
            <li>Language</li>
            <li>Information about your interests collected through Google Analytics</li>
            <li>Device Information collected through Google Analytics</li>
            <li>Location Information collected through Google Analytics</li>
          </ul>

          <p>Using Your Information</p>
          <ul>
            <li>administering the A/R Experience</li>
            <li>normal everyday business purposes, such as providing services to you</li>
            <li>business research and analysis</li>
            <li>marketing products and services of Prudential in which you may be interested</li>
            <li>as required by law</li>
          </ul>

          <p>Sharing Your Information</p>
          <p>Prudential may share your personal information among Prudential companies and with other non-Prudential companies who perform services for Prudential or on Prudential’s behalf for marketing products and services of Prudential in which you may be interested. You can limit this sharing by following the instructions described in this notice.</p>
          <p>Prudential may also share your personal information with certain trusted third-party companies and individuals to help Prudential provide, analyze, and improve services it provides to you (including but not limited to data storage, maintenance services, database management, web analytics, and improvement of the services’ features). These third parties may have access to your information only for purposes of performing these tasks on Prudential’s behalf and under obligations similar to those in this Privacy Policy. As of the date this policy went into effect, Prudential uses Coffee Digital and Google Analytics to collect, store, and/or process some of your information relating to the A/R Experience (for example, the Personal Information listed above).</p>
          <p>Prudential may also share your personal information as permitted or required by law, including, for example, to law enforcement officials and regulators, in response to subpoenas, and to prevent fraud.</p>
          <p>Unless you agree otherwise, we do not share your personal information with non-Prudential companies for them to market their products or services to you.</p>
          <p>Limiting Our Sharing–Opt Out/Privacy Choice</p>
          <p>You may tell Prudential not to share your personal information among Prudential companies for marketing purposes by “opting out” of such sharing. To limit Prudential’s sharing for these purposes:</p>
          <ul>
            <li>visit Prudential online at: prudential.com/privacyoptout</li>
            <li>call Prudential at: 1-877-248-4019</li>
          </ul>
          <p>If you previously told Prudential since 2016 not to share your personal information among Prudential companies for marketing purposes, you do not need to tell Prudential not to share your information again.</p>
          <p>You are not able to limit Prudential’s ability to share your personal information among Prudential companies and with other non-Prudential companies for servicing and administration purposes.</p>
          <p>Children</p>
          <p>The A/R Experience is not directed at nor intended for children under the age of 18. In connection with the A/R Experience, Prudential also does not knowingly collect personal information from children under the age of 18. You can learn about children's privacy and the Children's Online Privacy Protection Act (COPPA) at the Federal Trade Commission's website at http://www.ftc.gov</p>
          <p>Retention Period</p>
          <p>Prudential retains personal information for as long as needed or permitted in light of the purpose(s) for which it was obtained and consistent with applicable law.</p>
          <p>California Consumer Privacy Act Disclosure</p>
          <p>This California Consumer Privacy Act of 2018 (“CCPA”) Disclosure is provided by Prudential Financial, Inc., its subsidiaries and affiliates, other than Assurance IQ, LLC and its own subsidiaries. The Prudential entities providing this Disclosure are referred to in this Disclosure as “Prudential” and include Prudential Financial, Inc. subsidiaries and affiliates that use the names Prudential, Pruco, PRIAC and PGIM. This Disclosure explains how Playbill and Prudential collect, use, and disclose personal information relating to California residents that are subject to the CCPA.</p>
          <p>Under the CCPA, “Personal Information” is information that identifies, relates to, or could reasonably be linked with a particular California resident or household. The CCPA, however, does not apply to certain information, such as information subject to certain federal privacy laws, such as the Gramm-Leach-Bliley Act (“GLBA”) and the Health Insurance Portability and Accountability Act (“HIPAA”).</p>
          <p>As a result, this disclosure does not apply with respect to information that Prudential collects about California residents who request or obtain Prudential’s financial products and services for personal family or household purposes or for long-term care or individual health products. For more information about how Prudential collects, discloses, and secures information in these and other contexts, please refer to Prudential’s Privacy Centers at www.prudential.com/links/privacy-center and www.pgim.com/links/privacy-center.</p>
          <p>Our Collection of Personal Information</p>
          <p>In connection with the A/R Experience, Prudential collects personal information submitted by California residents when they submit an A/R Experience entry on the A/R Experience website. The personal information collected includes the following information submitted by you:</p>
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Photograph</li>
            <li>Age</li>
            <li>Gender</li>
            <li>Language</li>
            <li>Information about your interests collected through Google Analytics</li>
            <li>Device Information collected through Google Analytics</li>
            <li>Location Information collected through Google Analytics</li>
          </ul>

          <p>Our Use of Personal Information</p>
          <p>In connection with the A/R Experience, Prudential will use the information submitted by California residents in the following ways:</p>
          <ul>
            <li>administering the A/R Experience</li>
            <li>normal everyday business purposes, such as providing services to you</li>
            <li>business research and analysis</li>
            <li>marketing products and services of Prudential in which you may be interested</li>
            <li>as required by law</li>
          </ul>
          <p>Our Disclosure of Personal Information</p>
          <p>Prudential may share your name and personal contact information among Prudential companies and with other non-Prudential companies who perform services for Prudential or on Prudential’s behalf for marketing products and services of Prudential in which you may be interested. You can limit this sharing by following the instructions described in this notice.</p>
          <p>Prudential may also share your personal information with certain trusted third-party companies and individuals to help Prudential provide, analyze, and improve services it provides to you (including but not limited to data storage, maintenance services, database management, web analytics, and improvement of the services’ features). These third parties may have access to your information only for purposes of performing these tasks on Prudential’s behalf and under obligations similar to those in this Privacy Policy. As of the date this policy went into effect, Prudential uses Coffee Digital and Google Analytics to collect, process, and/or store some of your information (for example, you’re the Personal Information listed above).</p>
          <p>Prudential may also share your personal information as permitted or required by law, including, for example, to law enforcement officials and regulators, in response to subpoenas, and to prevent fraud.</p>
          <p>Unless you agree otherwise, we do not share your personal information with non-Prudential companies for them to market their products or services to you.</p>
          <p>Prudential does not “sell” Personal Information relating to California residents within the meaning of the CCPA. For purposes of this Disclosure, “sell” means the disclosure of Personal Information for monetary or other valuable consideration.</p>
          <p>Your Right to Know and to Delete</p>
          <p>If you are a California resident, you may request that Prudential disclose to you the following information covering the 12 months preceding your request:</p>
          <ul>
            <li>(1) the categories of Personal Information Prudential collected about you and the categories of sources from which Prudential collected such Personal Information;</li>
            <li>(2) the business or commercial purpose for collecting Personal Information about you;</li>
            <li>(3) the categories of Personal Information about you that Prudential disclosed to third parties for a business purpose and the categories of third parties to whom Prudential disclosed Personal Information (if applicable);</li>
            <li>(4) the specific pieces of Personal Information Prudential collected about you.</li>
          </ul>
          <p>If you are a California resident, you may also request that Prudential delete Personal Information collected from you.</p>
          <p>Prudential may decline to honor your request, in full or part, for certain reasons, such as a legal exemption. For example, if the information you are requesting is subject to certain federal privacy laws, like the GLBA or HIPAA, that are outside the scope of the CCPA rights requests, Prudential may decline to honor your request. In some instances, Prudential may decline to honor your request if we cannot verify your identity or confirm that the Personal Information that we maintain relates to you, or if we cannot verify that you have the authority to make a request on behalf of another individual. In other instances, Prudential may decline to honor your request where an exception applies, such as where the disclosure of Personal Information would adversely affect the rights and freedoms of another consumer. We may also decline to honor your request where the Personal Information that we maintain about you is not subject to the CCPA’s access and/or deletion right, such as information relating to our employees and contractors that is used for our employment and vendor management purposes, as well as information relating to beneficiaries or dependents of our employees that is used to administer employee benefits.</p>
          <p>You have the right to be free from unlawful discrimination for exercising your rights under the CCPA.</p>
          <p>How to Make Requests</p>
          <p>If you are a California resident, you may make a request for the disclosures described above or make a request to delete Personal Information Prudential collected from you, by contacting Prudential at:</p>
          <ul>
            <li>(1) 1-844-PRU-DSAR (1-844-778-3727); or</li>
            <li>(2) www.prudential.com/links/privacy-center/data-requests.</li>
          </ul>
          <p>When you make a request, Prudential will attempt to verify that you are who you say you are. For example, if you have an online account with Prudential, Prudential will require that you log into that account. If you do not have an online account with Prudential, Prudential will use a third-party verification service to verify your identity.</p>
          <p>When you make a request, Prudential will ask you to provide the following minimum information to identify yourself:</p>
          <ul>
            <li>(1) Name;</li>
            <li>(2) Email Address;</li>
            <li>(3) Country/State of Residence.</li>
          </ul>
          <p>In some instances, you will also be asked additional questions to verify your identity.</p>
          <p>Authorized Agents</p>
          <p>A California resident may authorize an agent to make an access or deletion request on behalf of the California resident. A California resident’s authorized agent may make a request on behalf of the California resident by contacting Prudential at the contact information above.</p>
          <p>In general, if an authorized agent makes a request on behalf of a California resident, Prudential will:</p>
          <p>(1) require that the authorized agent provide proof of its registration with the California Secretary of State to conduct business in California;</p>
          <p>(2) require that the California resident provide the authorized agent with written permission signed by the California resident authorizing the agent to make the request on the individual’s behalf (“Signed” means that the permission has either been physically signed or provided electronically in accordance with the Uniform Electronic Transactions Act, Civil Code 1633.7 et seq.);</p>
          <p>(3) verify the identity of the California resident as we would if the California resident were making the request directly; and</p>
          <p>(4) obtain verification from the California resident that the individual the authorized agent permission to make the request.</p>
          <p>Questions</p>
          <p>If you have any questions after reviewing this notice, please contact Prudential at
            <a href="mailto:Global.Privacy@prudential.com">Global.Privacy@prudential.com</a> or 1-800-PRU-HELP (778-4357).
          </p>
          <p>Prudential reserves the right to modify this notice at any time. Additional information about Prudential’s privacy practices is available at www.prudential.com.</p>
          <p>Information may be shared among the Prudential companies listed below.</p>
          <p><u>Insurance Companies and Insurance Company Separate Accounts</u></p>
          <p>The Prudential Insurance Company of America; Prudential Annuities Life Assurance Corporation; Pruco Life Insurance Company; Pruco Life Insurance Company of New Jersey; Prudential Retirement Insurance and Annuity Company (PRIAC); CG Variable Annuity Account I and CG Variable Annuity Account II; Prudential Legacy Insurance Company of New Jersey; All insurance company separate accounts that include the following names or are otherwise identified as maintained by an entity that includes the following names: Prudential, Pruco, or PRIAC.</p>
          <p><u>Insurance Agencies</u></p>
          <p>Prudential Insurance Agency, LLC; Mullin TBG Insurance Agency Services, LLC; Assurance IQ, LLC.</p>
          <p><u>Broker-Dealers and Registered Investment Advisers</u></p>
          <p>AST Investment Services, Inc.; Prudential Annuities Distributors, Inc.; Global Portfolio Strategies, Inc.; Pruco Securities, LLC; PGIM, Inc.; Prudential Investment Management Services LLC; PGIM Investments LLC; Prudential Private Placement Investors, L.P., Prudential Customer Solutions LLC; QMA LLC; Jennison Associates LLC</p>
          <p><u>Bank and Trust Companies</u></p>
          <p>Prudential Bank & Trust, FSB; Prudential Trust Company</p>
          <p><u>Investment Companies and Other Investment Vehicles</u></p>
          <p>PGIM Funds; Prudential Insurance Funds; Prudential Capital Partners, L.P.; Advanced Series Trust; PGIM Private Placement Investors, Inc.; All funds that include the following names: Prudential, PCP, PGIM, PEP, or PCEP</p>
          <p><u>Other Companies</u></p>
          <p>Prudential Workplace Solutions Group Services, LLC; Prudential Mutual Fund Services LLC</p>

          <p>Effective November 2021</p>

        </div>
        <div className="back-fix-btn w-100 p-3 d-flex align-items-center justify-content-center">
          <Button
            variant="primary"
            className="fs-md text-uppercase fw-bold rounded-0 py-1 px-3 text-dark text-decoration-none"
            onClick={handleGoBack}
          >
            <Image src={leftArrow} alt="right arrow" color="#000000" className="me-2 text-black" />
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

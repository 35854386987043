import React from "react";

const CirularCheckbox = ({ isChecked, toggleIsChecked, color = "" }) => (
  <div
    style={{ color }}
    onClick={() => {
      toggleIsChecked(!isChecked);
    }}
  >
    {isChecked ? (
      <i className="bi bi-check-circle"></i>
    ) : (
      <i className="bi bi-circle"></i>
    )}
  </div>
);

export default CirularCheckbox;
